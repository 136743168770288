.react-calendar {
  width: 100%;
}

.react-calendar__month-view__days__day--weekend {
  color: #aa2e25;
}

.react-calendar__tile--now {
  background-color: inherit;
}

.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0;
}

.available-spots {
  background-color: #33eb91;
}
