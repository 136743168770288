.common {
  /*  background-color: #f8f8f8; */
  color: grey;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  gap: 20px;
}

.h1 {
  font-weight: 400;
  margin: 0 0 20px 0;
  font-size: 28px;
  color: #444;
}

.settingsCard {
  width: 100px;
  height: 100px;
  background-color: grey;
}
