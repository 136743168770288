.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  opacity: 0.4;
  transition-duration: 400ms;
  position: relative;
  color: #005f76;
}

.selected {
  opacity: 1;
  position: relative;
  margin-top: -2px;
  color: #005f76;
}

.text {
  font-size: 13px;
  font-weight: 400;
}
