@media only screen and (min-width: 600px) {
    .auth {
        width: 500px !important;
        height: 600px !important;
    }

    .wrapper {
        justify-content: center;
        align-items: center;
    }
}

.wrapper {
    /* background-color: #202536; */
    background-image: url('/public/images/pexels-valdemaras-d-784301-1680381.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    height: 100vh;
    width: 100vw;
    padding: 10px;
    box-sizing: border-box;
}

.app-version {
    font-size: 14px !important;
    color: white;
    font-weight: 500;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 100;
}

.auth {
    display: grid;
    background-color: white;
    height: 100%;
    width: 100%;
    z-index: 200;
    box-sizing: border-box;
    border-radius: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1.2fr 25px;
    overflow: hidden;
    grid-template-areas:
        'featured'
        'form'
        '.';
}

.link {
    color: #00000088;
}

.featured {
    grid-area: featured;
    overflow: hidden;
    padding: 20px;
}

.form {
    grid-area: form;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.svg {
    width: 100%;
    height: 100%;
}
