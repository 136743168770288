.common {
  /* background-color: #f8f8f8; */
  color: grey;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.h1 {
  font-weight: 400;
  margin: 0 0 20px 0;
  font-size: 28px;
  color: #444;
  text-align: center;
}

.h3 {
  font-weight: 200;
  margin: 0 0 20px 0;
  color: #444;
  text-align: center;
}

.statsCardGrid {
  display: grid;
  grid-template-columns: auto 40px;
  grid-template-rows: 40px auto;
  grid-template-areas:
    'label icon'
    'content .';
}

.icon {
  background-color: #005f7633;
  color: #005f76;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  grid-area: icon;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
