@media only screen and (min-width: 600px) {
  .app {
    width: 850px !important;
    height: 850px !important;
    overflow: hidden;
    border-radius: 10px;
  }

  .wrapper {
    justify-content: center;
    align-items: center;
    /* background-color: #202536; */
  }
}

.wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.app {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 80px;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  border: 1px solid #d8d8d8;
}

main {
  overflow: auto;
}

nav {
  background-color: white;
  color: #005f76;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px 0px;
  z-index: 1000;
}
