.timeline {
  padding: 20px;
  overflow: auto;
  flex-grow: 1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -100px 36px -90px inset;
}

.box {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  min-height: 150px;
}

.dateWindow {
  display: flex;
  justify-content: end;
  border-right: 2px solid #005f7666;
  position: relative;
  box-sizing: border-box;
  color: black;
  position: relative;
  width: 90px;
}

.date {
  font-size: 25px;
  margin-right: 10px;
  text-align: right;
  position: absolute;
  right: 10px;
  top: 15px;
}

.dot {
  position: absolute;
  margin-top: 40px;
  margin-right: -15px;
  border-radius: 50px;
  border: 4px solid #ffffffff;
  background-color: #005f76;
  height: 20px;
  width: 20px;
}

.pulse {
  animation: pulse-animation 3s infinite ease-out;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 -25px #005f76;
  }
  50% {
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
}
