.common {
  /* background-color: #f8f8f8; */
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}

.h1 {
  font-weight: 200;
  margin: 0 0 20px 0;
  font-size: 28px;
  color: #444;
}

.content {
  display: flex;
  flex-direction: column;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.tableRow:hover {
  background-color: #d8d8d8;
  cursor: pointer;
}
