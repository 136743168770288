.common {
  /* background-color: #f8f8f8; */
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}

.h1 {
  font-weight: 400;
  margin: 0 0 20px 0;
  font-size: 28px;
  color: #444;
}

.content {
  display: flex;
  flex-direction: column;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.gallery {
  height: 100%;
  width: 100%;
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 10px;
  box-sizing: border-box;
  align-content: start;
  margin-bottom: 20px;
}

.image {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
