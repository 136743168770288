.card {
  background-color: #005f76;
  height: 50px;
  width: 100%;
  margin-bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
}

.metaRow {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  overflow: hidden;
  border-radius: 5px;
}

.metaDisplay {
  background-color: #888888;
  color: white;
  padding: 5px;
  font-weight: bold;
}

.metaContent {
  background-color: #d8d8d8;
  color: black;
  font-size: 18px;
  padding: 10px;
}
